import React from "react"

import "../assets/styles/global.css"
import BasicLayout from "../layouts/BasicLayout"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import NavBarWrapper from "../components/Bar/NavBarWrapper"
import Subscribe from "../components/Content/Ebooks/Subscribe"
import withLocation from "../components/Tools/WithLocation"
import Footer from "../components/Bar/footer"
import Carroussel from "../components/Carroussel/Carroussel"


const BookPageTemplate = ({ pageContext, search, location }) => {

  return (
    <BasicLayout pageContext={pageContext} SEOOptions={pageContext.SEOOptions}>
      <NavBarWrapper background={"white"} location={location}/>
      <div css={styles}>
        <div className="header">
          <div className="title-wrapper">
            <h3 className="title-1">{pageContext.node.frontmatter.title_1}</h3>
          </div>
          <h1 className="title-2">
            {pageContext.node.frontmatter.title_2}
          </h1>
        </div>
        <div className="wrapper">
          <Img
            style={{ zIndex: "-1" }}
            placeholderStyle={{
              width: "100%",
              display: "block",
              objectFit: "contain",
              position: "unset"
            }}
            fluid={pageContext.node.frontmatter.ebook_image.childImageSharp.fluid}
            alt="ebook"
          />

          <div className="two">
            <h1 className="accroche">{pageContext.node.frontmatter.accroche}</h1>
            <ReactMarkdown
              className="description_1"
              source={pageContext.node.frontmatter.description_1}
            />
            <Subscribe currentEbook={pageContext.node} search={search} />
            <ReactMarkdown
              className="description_2"
              source={pageContext.node.frontmatter.description_2}
            />
          </div>
        </div>
      </div>
      <div style={{
        width:'80%',
        margin : 'auto',
        height: '500px'
      }}>
        <Carroussel
          theme={"light"}
        />
      </div>
      <Footer location={location} isFullpage={false} />
    </BasicLayout>
  )
}


BookPageTemplate.propTypes = {
  search: PropTypes.object
}

export default withLocation(BookPageTemplate)

const styles = css`
  .header {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .logo {
    width: 11.52rem;
    display: block;
    margin: auto;
  }

  .title-1 {
    font-size: 40px;
    color: #133c9e;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title-2 {
    display: inline-block;
    font-size: 40px;
    color: #133c9e;
    border-bottom: 3px solid #133c9e;
    margin-top: 0;
  }

  .checkmark-svg {
    width: 30px;
    height: 30px;
    margin-left: 8px;
  }

  .wrapper {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-areas: "a a";
    margin-top: 50px;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
  }

  .title-wrapper {
    display: grid;
    margin: 50px auto auto auto;
    justify-content: center;
    max-width: 1200px;
  }

  .gatsby-image-wrapper {
    left: -10%;
    width: 135%;
    margin: auto;
  }

  .two {
    display: grid;
    grid-auto-rows: 0.5fr;
    max-width: 460px;
    align-content: center;
    padding: 30px;

    h1 {
      margin-top: 8px;
      margin-bottom: 37px;
    }
  }

  .accroche {
    font-weight: 500;
    font-size: 27px;
    margin: .67em 0;
  }

  .description_1 {
    font-size: 22px;
    margin-top: 0;

    p {
      margin: 0;
      margin-bottom: 2%;
    }

    h6 {
      margin-bottom: 1%;
    }
  }


  .Subcribe_component {
    margin-top: 200px
  }

  .description_2 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 28px;

    p {
      margin-bottom: 0px;
      margin-top: 5px;
    }

    p img {
      max-width: 58%;
    }
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      grid-template-areas: "a";

    }

    .checkmark-svg {
      width: 20px;
      height: 20px;
    }

    .title-2 {
      font-size: 25px;
      color: #133c9e;
      margin: auto 10px;
    }

    .gatsby-image-wrapper {
      width: 100%;
      margin: auto;
      left: 0%;
    }

    .two {
      text-align: left;
      margin: auto;
    }
  }
`
